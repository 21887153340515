export function checkMobile(location) {
    let path = normalizePath(location.pathname);
    if (path && path.indexOf('/mobile-') === 0) {
        return true;
    }
    return false;
}

export function normalizePath(path) {
  if (path.slice(0,2) === '//') {
    path = path.slice(1);
  }

  if (path[path.length - 1] !== '/') {
    path += '/';
  }

  return path;
}
