import * as _User from "./Reducers/User";
import * as _Lang from "./Reducers/Lang";
import * as _Product from './Reducers/Product';
import * as _AppState from './Reducers/AppState';
import { namedExportWithMemorizedOnce } from "./Utils/FpUtil";
import ParseQuery from "./Utils/ParseQuery";

let User = namedExportWithMemorizedOnce(_User);
let Lang = namedExportWithMemorizedOnce(_Lang);
let Product = namedExportWithMemorizedOnce(_Product);
let AppState = namedExportWithMemorizedOnce(_AppState);

const selectors = {
  getQueryParams: ownProps => ParseQuery(ownProps.location.search),
  getLang: state => Lang.getLang(state.lang),
  getLoginUser: state => User.getLoginUser(state.user),
  getItemById: (state, id) => Product.getItemById(state.product, id),
  getPatient: state => User.getPatient(state.user),
  getDoctors: (state) => AppState.getDoctors(state.appState),
};

export default selectors;
