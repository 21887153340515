import React, { Component } from 'react';
import styled from 'styled-components';

class Footer extends Component {
  render() {
    let { extraStyle = '' } = this.props;

    return (
      <Wrapper extraStyle={extraStyle}>
        <Content>
          <CompanyName>忻旅科技股份有限公司</CompanyName>
          <CompanyInfo>
            <p>地址：台北市中山區南京東路二段160號6樓</p>
            <p>電話：0920-691-019</p>
            <p>聯絡信箱：contact@revteltech.com</p>
          </CompanyInfo>
        </Content>
      </Wrapper>
    );
  }
}

let Wrapper = styled.div`
  background-color: #d0d0d0;
  width: 100vw;
  padding: 60px 10px 60px 10px;
  color: white;
  ${props => props.extraStyle};
`;
let Content = styled.div`
  max-width: 800px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
`;
let CompanyName = styled.h2`
  width: 400px;
  text-align: center;
  margin: 0;
`;
let CompanyInfo = styled.div`
  width: 400px;
  color: white;

  @media screen and (max-width: 600px) {
    width: 300px;
  }
`;

export default Footer;
