import React from 'react';
import styled from 'styled-components';

export const BgImage = styled.div`
  background-color: ${props => props.color || '#d0d0d0'};
  background-image: url(${props => props.image});
  background-size: ${props => props.size || 'cover'};
  background-repeat: ${props => props.repeat || 'no-repeat'};
  background-position: ${props => props.position || 'center'};
  width: 100%;
  height: 100%;
  ${props => props.extraCss};
`;

export const Container = styled.div`
  ${props => props.extraCss};
`;

export const Center = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${props => props.extraCss};
`;

export const MaxWidth = styled.div`
  max-width: ${props => props.width}px;
  margin: ${props => props.margin || '0 auto'};
  ${props => props.extraCss};
`;

export const Row = styled.div`
  display: flex;
  align-items: ${props => props.align || 'stretch'};
  justify-content: ${props => props.justify || 'flex-start'};
  flex-wrap: ${props => (props.wrap ? 'wrap' : 'nowrap')};
  ${props => props.extraCss};
`;

export const FixRatioImage = ({
    image,
    width = '100%',
    heightRatio = '56.25%',
    extraStyle,
  }) => (
    //heightRatio: (9: 16 = 56.25%) (3 : 4 = 75%)
    <div style={{ width: width, ...extraStyle }}>
      <div style={{ position: 'relative', top: 0, paddingTop: heightRatio }}>
        <BgImage image={image} extraCss="position: absolute; top: 0px;" />
      </div>
    </div>
  );

  export let NativeButton = styled.button`
  display: inline-block;
  border: none;
  padding: 8px 12px;
  margin-left: 10px;
  text-decoration: none;
  background: ${props => props.color || 'teal'};
  color: #ffffff;
  font-family: sans-serif;
  font-size: ${props => props.fontSize || '20px'};
  cursor: pointer;
  text-align: center;
  transition: background 250ms ease-in-out,
              transform 150ms ease;
  -webkit-appearance: none;
  -moz-appearance: none;

  & :hover {
      font-weight: 900;
      background: ${props => props.color || 'teal'};
  }

  & :focus {
      background: ${props => props.color || 'teal'};
      outline: none;
  }

  & :active {
      transform: scale(0.80);
  }

  & :disabled {
    background-color: #cccccc;
    color: #666666;
  }

  ${props => props.extraCss || ''}
`

export const Button = styled.div`
  cursor: pointer;
  min-width: 100px;
  padding: 8px 16px;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: white;
  color: #00BCD4;
  font-size: 14px;

  transition: background 250ms ease-in-out,
  transform 150ms ease;

  & :active {
    transform: scale(0.80);
  }

  ${props => props.extraCss || ''}
`;

export const FlatButton = styled.div`
  cursor: pointer;
  min-width: 105px;
  padding: 8px 16px;

  display: flex;
  justify-content: center;
  align-items: center;

  color: #00BCD4;
  font-size: 14px;

  ${props => props.haveBorderBottom ? "border-bottom: 3px solid orange;" : ""}
  ${props => props.extraCss || ''}
`;

export const Spinner = styled.div`
  width: 45px;
  height: 45px;
  border-radius: 50%;
  border: 4px solid lightgray;
  border-top: 4px solid white;
  animation: spin 1s ease-in-out infinite;
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;