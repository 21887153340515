import { combineReducers } from 'redux';
import user from './User';
import lang from './Lang';
import product from './Product';
import appState from './AppState';

export default combineReducers({
  user,
  lang,
  product,
  appState,
});
