import React, { Component } from "react";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import "./index.css";
import { checkMobile, normalizePath } from "./Utils/LocationUtil";
import Navbar from "./Components/Navbar";
import Footer from "./Components/Footer";
import Delighters from "./Components/ReactDelighters";


class Layout extends Component {
  componentDidMount() {
    window.addEventListener("scroll", this._onScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this._onScroll);
  }

  render() {
    let { location } = this.props;

    function hasFooter(path) {
      if (path.indexOf("/record") === 0) {
        return false;
      } else if (path.indexOf("/create-record") === 0) {
        return false;
      } else if (path.indexOf("/edit-record") === 0) {
        return false;
      } else if (path.indexOf("/profile") === 0) {
        return false;
      } else if (path.indexOf("/videos" === 0)) {
        return false;
      } else if (path.indexOf("/faq") === 0) {
        return false;
      } else if (path.match(/^\/$/)) {
        return false;
      }
      return true;
    }

    function hasNavbarLogo(path) {
      if (path.indexOf("/videos") === 0) {
        return true;
      }
      return false;
    }

    let navbarlogo = hasNavbarLogo(normalizePath(location.pathname));
    let footer = hasFooter(normalizePath(location.pathname));
    let inMobileApp = checkMobile(location);

    let {
      location: { pathname },
    } = this.props;
    let passBreakpoint = !!this.passBreakpoint;
    pathname = this._normalizePath(pathname);

    let withPaddingTop = true;

    if (pathname === "/") {
      withPaddingTop = false;
    }

    return (
      <Delighters>
        <Wrapper withPaddingTop={withPaddingTop}>
          <Helmet>
            <title>Revtel Tech 忻旅科技</title>
            <meta
              name="description"
              content="Revtel Tech 忻旅科技 | 網站、APP、物聯網開發"
            />
            <link rel="shortcut icon" href="/favicon.ico" />
          </Helmet>

          {!inMobileApp && <Navbar logo={navbarlogo} passBreakpoint={passBreakpoint || withPaddingTop} />}

          <Content>{this.props.children}</Content>

          {footer && !inMobileApp && <Footer pathname={pathname} />}
        </Wrapper>
      </Delighters>
    );
  }

  _normalizePath = path => {
    if (path.slice(0, 2) === "//") {
      path = path.slice(1);
    }

    if (path[path.length - 1] !== "/") {
      path += "/";
    }

    return path;
  };

  _onScroll = e => {
    const breakpoint = 600;
    let scrollTop = document.querySelector("html").scrollTop;

    if (scrollTop > breakpoint) {
      if (!this.passBreakpoint) {
        this.passBreakpoint = true;
        this.forceUpdate();
      }
    } else {
      if (this.passBreakpoint) {
        this.passBreakpoint = false;
        this.forceUpdate();
      }
    }
  };
}

const Wrapper = styled.div`
  min-height: calc(100vh);
  background-color: white;
  display: flex;
  flex-direction: column;
  ${props => (props.withPaddingTop ? "padding-top: 60px;" : "")}
`;

const Content = styled.div`
  flex: 1;
  align-self: stretch;
`;

export default Layout;
