import Actions from "./Actions";

const initialState = {
    doctors: []
};

export default function reducer(state = initialState, { type, payload }) {
    switch (type) {
        case Actions.SET_DOCTORS:
            return {
                ...state,
                doctors: payload
            }
        default:
            break;
    }

    return state;
}

export function getDoctors(state) {
    return state.doctors;
}