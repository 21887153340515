export default {
  SET_LANG: 'SET_LANG',
  SET_PATIENT_DATA: 'SET_PATIENT_DATA',
  SET_DOCTORS: 'SET_DOCTORS',

  // ItemComment, ItemCommentResponse
  SET_ITEM_COMMENTS: 'SET_ITEM_COMMENTS',
  SET_ITEM_COMMENT: 'SET_ITEM_COMMENT',
  REMOVE_ITEM_COMMENT: 'REMOVE_ITEM_COMMENT',
  SET_ITEM_COMMENT_RESPONSE: 'SET_ITEM_COMMENT_RESPONSE',
  REMOVE_ITEM_COMMENT_RESPONSE: 'REMOVE_ITEM_COMMENT_RESPONSE',

  LOADING_PAGE_DATA: 'LOADING_PAGE_DATA',
  LOAD_PAGE_DATA_DONE: 'LOAD_PAGE_DATA_DONE',

  NAVBAR_TOGGLE_STYLE: 'NAVBAR_TOGGLE_STYLE',
  LOGIN: 'LOGIN',
  AUTO_LOGIN: 'AUTO_LOGIN',
  LOGOUT: 'LOGOUT',
  UPDATE_PROFILE: 'UPDATE_PROFILE',

  FETCHING_HOME_SCREEN: 'FETCHING_HOME_SCREEN',
  FETCH_HOME_SCREEN_SUCCESS: 'FETCH_HOME_SCREEN_SUCCESS',
  FETCH_HOME_SCREEN_FAILURE: 'FETCH_HOME_SCREEN_FAILURE',

  FETCHING_CATEGORIES: 'FETCHING_CATEGORIES',
  FETCH_CATEGORIES_SUCCESS: 'FETCH_CATEGORIES_SUCCESS',
  FETCH_CATEGORIES_FAILURE: 'FETCH_CATEGORIES_FAILURE',

  FETCHING_ITEMS_IN_CATEGORY: 'FETCHING_ITEMS_IN_CATEGORY',
  FETCH_ITEMS_IN_CATEGORY_SUCCESS: 'FETCH_ITEMS_IN_CATEGORY_SUCCESS',
  FETCH_ITEMS_IN_CATEGORY_FAILURE: 'FETCH_ITEMS_IN_CATEGORY_FAILURE',
  SET_PAGED_CATEGORY_ITEMS: 'SET_PAGED_CATEGORY_ITEMS',
  SET_PAGE_OFFSET_FOR_CATEGORY: 'SET_PAGE_OFFSET_FOR_CATEGORY',
  SET_FILTERS_FOR_CATEGORY: 'SET_FILTERS_FOR_CATEGORY',

  SET_ITEM: 'SET_ITEM',

  FETCHING_ITEM_BY_ID: 'FETCHING_ITEM_BY_ID',
  FETCH_ITEM_BY_ID_SUCCESS: 'FETCH_ITEM_BY_ID_SUCCESS',
  FETCH_ITEM_BY_ID_FAILURE: 'FETCH_ITEM_BY_ID_FAILURE',

  FETCHING_ITEM_BY_KEYWORD: 'FETCHING_ITEM_BY_KEYWORD',
  FETCH_ITEM_BY_KEYWORD_SUCCESS: 'FETCH_ITEM_BY_KEYWORD_SUCCESS',
  FETCH_ITEM_BY_KEYWORD_FAILURE: 'FETCH_ITEM_BY_KEYWORD_FAILURE',

  INIT_LANG_DATA: 'INIT_LANG_DATA',
  CHANGE_LANG: 'CHANGE_LANG',
  FETCH_CAPTCHA_SUCCESS: 'FETCH_CAPTCHA_SUCCESS',

  MAKE_ORDER_SUCCESS: 'MAKE_ORDER_SUCCESS',
  FETCH_ORDER_SUCCESS: 'FETCH_ORDER_SUCCESS',
  FETCH_ORDERS_SUCCESS: 'FETCH_ORDERS_SUCCESS',

  SET_ORDER: 'SET_ORDER',
  SET_ORDERS: 'SET_ORDERS',

  FETCH_SITENEWS_SUCCESS: 'FETCH_SITENEWS_SUCCESS',
};
