const ApiProxy = require("./ApiProxy");

module.exports = {
  async getVideoList() {
    return ApiProxy.get({
      path: "/api/video/"
    });
  },

  async bootstrap() {
    return ApiProxy.get({
      path: "/api/bootstrap/"
    });
  },

  async login({ username, password }) {
    return ApiProxy.post({
      path: "/api/user/login/",
      data: { username, password }
    });
  },

  async socialSingin({ socialType, token }) {
    return ApiProxy.post({
      path: `/api/social_signin/${socialType}/`,
      data: { token }
    });
  },

  async getProfile(id) {
    return ApiProxy.get({
      path: `/api/user/profile/${id}/`
    });
  },

  async getMyProfile() {
    return ApiProxy.get({
      path: `/api/user/profile/`
    });
  },

  async getDoctors() {
    return ApiProxy.get({
      withToken: false,
      path: `/api/doctor/`
    });
  },

  // async getMyHealthRecords() {
  //   return ApiProxy.get({
  //     path: `/api/health_measure/`,
  //   });
  // },

  async editMyProfile(data) {
    data = { ...data };
    if (data.birthday === "") {
      delete data.birthday;
    }

    if (data.next_date === "") {
      delete data.next_date;
    }
    return ApiProxy.post({
      path: "/api/user/profile/",
      data
    });
  },

  async editProfile({ id, ...data }) {
    data = { ...data };
    if (data.birthday === "") {
      delete data.birthday;
    }

    if (data.next_date === "") {
      delete data.next_date;
    }

    return ApiProxy.post({
      path: `/api/user/profile/${id}/`,
      data
    });
  },

  async postHealthRecord({ male, height, weight, date }) {
    return ApiProxy.post({
      path: "/api/health_measure/",
      data: { male, height, weight, date }
    });
  },

  async getHealthRecord(id) {
    return ApiProxy.get({
      path: `/api/health_measure/${id}/`
    });
  },

  async editHealthRecord({ id, male, height, weight, date }) {
    return ApiProxy.put({
      path: `/api/health_measure/${id}/`,
      data: { male, height, weight, date }
    });
  },

  async deleteHealthRecord(id) {
    return ApiProxy.delete({
      path: `/api/health_measure/${id}/`
    });
  },

  async register({ username, email, password }) {
    return ApiProxy.post({
      path: "/api/user/register/",
      data: { username, email, password }
    });
  },

  async resetValidation({ username, password }) {
    return ApiProxy.post({
      path: "/api/users/reset_validation/",
      data: {
        username,
        password
      }
    });
  },

  async changePassword({ username, password, newPassword }) {
    return ApiProxy.post({
      path: `/api/user/change_password/`,
      data: {
        username,
        password,
        new_password: newPassword
      }
    });
  },

  async resetPassword({ email }) {
    return ApiProxy.post({
      path: `/api/users/reset_password/`,
      data: {
        email
      }
    });
  },

  async fetchItemById(id) {
    return ApiProxy.get({
      path: `/api/items/${id}/`
    });
  },

  async fetchItemsByCategory(id) {
    return ApiProxy.get({
      path: `/api/categories/${id}/items/`
    });
  },

  async fetchItemComments(id) {
    return ApiProxy.get({
      path: `/api/items/${id}/comments/`
    });
  },

  async createItemComment({ item, text }) {
    return ApiProxy.post({
      path: `/api/items/${item.id}/comments/`,
      data: {
        text
      }
    });
  },

  async editItemComment({ item, itemComment, text }) {
    return ApiProxy.put({
      path: `/api/items/${item.id}/comments/${itemComment.id}/`,
      data: {
        text
      }
    });
  },

  async deleteItemComment({ item, itemComment }) {
    return ApiProxy.delete({
      path: `/api/items/${item.id}/comments/${itemComment.id}/`
    });
  },

  async createItemCommentResponse({ item, comment, text }) {
    return ApiProxy.post({
      path: `/api/items/${item.id}/comments/${comment.id}/responses/`,
      data: {
        text
      }
    });
  },

  async editItemCommentResponse({ item, comment, response, text }) {
    return ApiProxy.put({
      path: `/api/items/${item.id}/comments/${comment.id}/responses/${
        response.id
      }/`,
      data: {
        text
      }
    });
  },

  async deleteItemCommentResponse({ item, comment, response }) {
    return ApiProxy.delete({
      path: `/api/items/${item.id}/comments/${comment.id}/responses/${
        response.id
      }/`
    });
  },

  async preCheckout(data) {
    return ApiProxy.post({
      secure: true,
      path: `/api/pre_checkout/`,
      data: {
        data
      }
    });
  },

  async checkout({ meta, hash }) {
    return ApiProxy.post({
      secure: true,
      path: `/api/checkout/`,
      data: {
        meta,
        hash
      }
    });
  },

  async paypalOnSuccess(uid, data) {
    return ApiProxy.post({
      secure: true,
      path: `/api/paypal/result/${uid}/`,
      data
    });
  },

  async getOrderList() {
    return ApiProxy.get({
      secure: true,
      path: `/api/order/`
    });
  },

  async getOrder(id) {
    return ApiProxy.get({
      secure: true,
      path: `/api/order/${id}/`
    });
  },

  async fetchItems() {
    // products.json
    return ApiProxy.get({
      path: "/api/items/"
    });
  }
};
