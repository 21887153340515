import ActionCreator from './ActionCreator';
import ParseQuery from './Utils/ParseQuery';

async function dispatchRouteInitClientActions({ store, location }) {
  let { appActions } = ActionCreator(store.dispatch);
  let queryParams = ParseQuery(location.search);

  if (queryParams.token) {
      await appActions.autoLogin(queryParams.token);
  } else if (!store.getState().user.data) {
      await appActions.autoLogin();
  }

  if (location.pathname.indexOf('/mobile-patient') === 0) {
    await appActions.getUserProfile(queryParams.id);
  }
}

export { dispatchRouteInitClientActions };
