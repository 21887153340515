export default function(state = {}, action) {
  switch (action.type) {
    default:
      return state;
  }
}

export function getItemById(state, id) {
  return state.map[id];
}
